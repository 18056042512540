import { useGlobalState } from './use-global-state';

type FeatureName =
  | 'enable_multistorelogin'
  | 'displayCampaignFilters'
  | 'displayGlobalAttrWindow'
  | 'displayReportSaverAttrWindow'
  | 'notification_badge_upload'
  | 'segment_estimation'
  | 'brevo_email'
  | 'segment_estimation'
  | 'enable_ai_assist'
  | 'brevo_mfe'
  | 'sms_enabled'
  | 'enable_multistorelogin'
  | 'brevo_merchant'
  | 'tier_based_pricing'
  | 'has_brevo_list_mapping'
  | 'hide_email_modal'
  | 'campaign_template_banner'
  | 'sms_onboarding'
  | 'email_integrations_tab';

export function useOwlyFeatureFlag(
  feature: FeatureName,
  enabledValue: string = 'enabled',
) {
  const {
    state: {
      isAuthenticating,
      user: {
        website: { flags },
      },
    },
  } = useGlobalState('user');

  return {
    isLoading: isAuthenticating,
    flag: flags[feature] === enabledValue,
  };
}
